import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

import quotes from "../content/quotes"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Styled = styled.div`
  width: 100%;
  background-size: contain;

  .overlay {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
`

const AuthorBackground = ({ isAbout, author, children }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "testimonials/monticello.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      tj: file(relativePath: { eq: "testimonials/monticello_library.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      frost: file(relativePath: { eq: "testimonials/road_less.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      nero: file(relativePath: { eq: "testimonials/rome_burning.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      noah: file(relativePath: { eq: "testimonials/flood.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      caesar: file(relativePath: { eq: "testimonials/death_of_caesar.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      crater: file(relativePath: { eq: "about/crater-two.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      baby: file(relativePath: { eq: "home/baby-and-steve.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }
    }
  `)

  const key = isAbout ? "crater" : author

  const fluid = data[key].childImageSharp.fluid

  return (
    <Styled>
      <BackgroundImage fluid={fluid}>
        <div className="overlay">{children}</div>
      </BackgroundImage>
    </Styled>
  )
}

export default AuthorBackground
