export default [
  {
    id: "caesar",
    author: `Julius Caesar`,
    quote: `All roads lead to Rome, but if you are looking for a quality Software Engineer, all fingers point to Steve.`,
  },
  {
    id: "eratosthenes",
    author: "Eratosthenes",
    quote: `It was easy to measure the size of the Earth... <br><br> I don't know if math will
  ever be able to measure the size of Steve’s heart.`,
  },
  {
    id: "tj",
    author: "Thomas Jefferson",
    quote: `Steve's works are to the library at Monticello, <br><br> what Yo-Yo Ma is to the actual Cello…`,
  },
  {
    id: "darwin",
    author: `Charles Darwin`,
    quote: `It is not the strongest of a species that survives, nor the most intelligent
  that survives. 
    <br><br>
  It is the one that is 'most adaptable to change.'  
  <br><br>
  ...and everytime I try to trade Steve four quarters for a dollar bill, he accepts.`,
  },
  {
    id: "nero",
    author: `Nero`,
    quote: `I thought Rome was lit. <br> But then I chilled with Steve.`,
  },
  {
    id: "albert",
    author: `Albert Einstein`,
    quote: `The definition of insanity is doing the same thing over and over again and
  expecting different results. 
  <br><br>
  Yet every time Steve tells that Knock Knock joke, I say, ‘Who’s there?' 
  <br><br>
  'Banana.'
  <br><br>
  'Banana,
  who? Steve, please. Banana who?! For the love of Science, Steve! Banana who?!'
  <br><br>
  He drives me insane.`,
    caption: "Albert trying to figure out who the Banana at the door is.",
  },
  {
    id: "noah",
    author: `Noah`,
    quote: `There is no one I would rather curl up with on a rainy day.`,
  },
  {
    id: "benjamin",
    author: `Benjamin Franklin`,
    quote: `Poor Richard only became that way because he bet against Steve.`,
    caption: "Ben and Steve making a music video for their Heavy Metal Album.",
  },
  // {
  //   id: "galileo",
  //   author: "Galileo Galilei",
  //   quote: `So, the Earth revolves around the sun... but MY world still revolves around Steve.`,
  // },

  // {
  //   id: "suntzu",
  //   author: `Sun Tzu`,
  //   quote: `Do you want a good strategy? Have Steve on your side for what's ahead.`,
  // },
  {
    id: "frost",
    author: `Robert Frost`,
    quote: `The Road Less Traveled actually had a sign post. <br><br> It read, 'Creswick Way.'`,
  },
]
